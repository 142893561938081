import { Search } from '@element-plus/icons-vue';
import BaseOption from '../../../common/base-option-dialog.vue';
export default {
  components: {
    Search,
    BaseOption
  },
  data() {
    return {
      table: {},
      pagination: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      form: {},
      keyword: '',
      warehouseAreaId: 0,
      warehouseAreaType: '',
      warehouseAreaVisible: false,
      formRules: {
        name: [{
          required: true,
          message: '请输入'
        }, {
          pattern: /^[A-Za-z0-9_-]+$/,
          message: '只允许英文，数字，下划线，中划线'
        }, {
          min: 1,
          max: 10,
          message: '最多只允许输入10位'
        }]
      }
    };
  },
  watch: {
    keyword(val) {
      if (val === '') {
        this.clearAll();
      }
    }
  },
  mounted() {
    this.getWarehouseArea();
  },
  methods: {
    async getWarehouseArea() {
      try {
        await this.$store.dispatch('system/base/getWarehouseArea', {
          params: {
            current_page: this.pagination.current_page,
            page_size: this.pagination.page_size,
            name: this.keyword
          }
        });
        let warehouseArea = this.$store.state.system.base;
        this.table = {
          tableData: warehouseArea.warehouseArea,
          tableFields: [{
            label: 'ID',
            prop: 'id',
            width: '90px',
            show: true
          }, {
            label: '仓库分布名称',
            prop: 'name',
            width: '1030px',
            show: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            width: '400px',
            show: true
          }]
        };
        this.total = warehouseArea.warehouseAreaLength;
      } catch (err) {
        return;
      }
    },
    queryList() {
      this.pagination.current_page = 1;
      this.getWarehouseArea();
    },
    clearAll() {
      this.pagination = {
        current_page: 1,
        page_size: 10
      };
      this.getWarehouseArea();
    },
    changePagination(val) {
      this.pagination = val;
      this.getWarehouseArea();
    },
    showWarehouseAreaDialog(type, id, name) {
      this.warehouseAreaType = type;
      if (type === 'update') {
        this.warehouseAreaId = id;
        this.form.name = name;
      } else {
        this.form.name = '';
      }
      this.warehouseAreaVisible = true;
    },
    closeWarehouseArea() {
      this.warehouseAreaVisible = false;
      this.$refs.form.resetFields();
    },
    async createWarehouseArea() {
      try {
        await this.$store.dispatch('system/base/createWarehouseArea', {
          name: this.form.name
        });
        this.warehouseAreaVisible = false;
        this.$refs.form.resetFields();
        this.pagination.current_page = 1;
        localStorage.removeItem('warehouse_area');
        this.getWarehouseArea();
      } catch (err) {
        return;
      }
    },
    async updateWarehouseArea() {
      try {
        await this.$store.dispatch('system/base/updateWarehouseArea', {
          id: this.warehouseAreaId,
          name: this.form.name
        });
        this.warehouseAreaVisible = false;
        this.$refs.form.resetFields();
        localStorage.removeItem('warehouse_area');
        this.getWarehouseArea();
      } catch (err) {
        return;
      }
    },
    async submitWarehouseArea() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.warehouseAreaType === 'create') {
            this.createWarehouseArea();
          } else {
            this.updateWarehouseArea();
          }
        }
      });
    }
  }
};